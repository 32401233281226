/*//////////////////////////////////////////////////////////////////
[ login ]*/
.limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #423f3f;
  }
  
  
  .wrap-login100 {
    width: 100%;
    background: #3a3838;
    overflow: hidden;
    
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
  
  }
  
  /*==================================================================
  [ login more ]*/
  .login100-more {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  
  .login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
  }
  
  
  
  /*==================================================================
  [ Form ]*/
  
  .login100-form {
    width: 560px;
    min-height: 100vh;
    display: block;
    background-color: #f7f7f7;
    padding: 173px 55px 55px 55px;
  }
  
  .login100-form-title {
    width: 100%;
    display: block;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 50px;
  }
  
  
  
  /*------------------------------------------------------------------
  [ Input ]*/

  .wrap-input100 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    height: 80px;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  
  .input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 18px;
    // color: #555555;
    border: none;
    outline: none;
    line-height: 1.2;
    padding: 0 26px;
  }
  
  input.input100 {
    height: 100%;
    transition: all 0.4s;
    border: none;
  }
  
  /*---------------------------------------------*/

  
  /*==================================================================
  [ Restyle Checkbox ]*/
  
  .input-checkbox100 {
    display: none;
  }
  
  .label-checkbox100 {
    font-size: 13px;
    color: #999999;
    line-height: 1.4;
  
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  
  .label-checkbox100::before {
    font-size: 13px;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background: #fff;
    border: 1px solid #6675df;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-checkbox100:checked + .label-checkbox100::before {
    color: #6675df;
  }
  
  .check-user-text{
    font-size: 14px;
    color: #ef3838;
    margin-top: 5px;
  }
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .login100-form-btn {
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: #6675df;
    font-size: 12px;
    color: #fff;
    border: none;
    outline: none;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.4s;
  }
  
  .login100-form-btn:hover {
    background: #ec6090;
  }

  /*==================================================================
[ checkbox form ]*/
.container-checkbox{
    display: flex;
    position: relative;
    padding: 15px 0;
    margin-top: 30px;
}
.remember-pw{
    .input-checkbox{
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
    }
    .label-checkbox{
        cursor: pointer;
        color: #999999;
    }
}
.forgot-pw{
    position: absolute;
    right: 0;
    a{
        color: #ec6090;
        &:hover{
            color: #999999;
        }
    }
}
  /*==================================================================
[ Social ]*/
.text-center{
margin-top: 20px;
padding: 20px 0;
.sign-up{
    color: #ec6090;
    &:hover{
        color: #999999;
    }
}
}

.login100-form-social{
text-align: center;
}

.login100-form-social-item {
    width: 40px;
    margin-right: 10px;
    color: #fff;
    border-radius: 50%;
    i{
        font-size: 30px;
    }
    .facebook-icon{
        color: #3b5998;
    }
    .google-icon{
        color: #4285F4;
    }
  }
  
  
  /*------------------------------------------------------------------
  [ Responsive ]*/
  
  @media (max-width: 992px) {
    .login100-form {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .login100-more {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .login100-form {
      width: 100%;
    }
  
    .login100-more {
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    .login100-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
    }
  }
  
