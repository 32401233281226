
.view_dialog{
display: flex !important;
}

.dialog_search {
    position: fixed;
    top: 80px;
    z-index: 100;
    width: 100%;
    min-height: 70vh;
    display: none;
    justify-content: center;
    background-color: #6c5d6b;
    padding: 15px;
    border-radius: 23px;
  }
  .close_search_dialog{
    position: fixed;
    z-index: 1;
    top: 13%;
    right: 5%;
    i {
        font-size: 30px;
        color: #CCCCCC;
    }
    &:hover{
        cursor: pointer;
    }
  }
  .dialog_search form {
    position: absolute;
    max-width: 790px;
    padding-top: 20vh;
    .info {
        font-size: 15px;
        color: #ccc;
        padding-left: 26px;
      }
      .inner-form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 34px;
        overflow: hidden;
        margin-bottom: 30px;
      }
      .inner-form .input-field {
        height: 68px;
        input {
            height: 100%;
            background: transparent;
            border: 0;
            display: block;
            width: 100%;
            padding: 10px 0;
            font-size: 16px;
            color: #000;
          }
      }
  }
  
  
    .dialog_search form .inner-form .input-field {
        input{
            &:hover{
                box-shadow: none;
                outline: 0;
            }
            &:focus{
                box-shadow: none;
                outline: 0;
            }
        }
    }
  
  .dialog_search form .inner-form .input-field.first-wrap {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background: #d9f1e3;
  }
  
  .dialog_search form .inner-form .input-field.first-wrap input {
        flex-grow: 1;
  }
  
  .dialog_search form .inner-form .input-field.first-wrap .icon_search_dialog {
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 20px;
    }
  }
  
  .dialog_search form .inner-form .input-field.second-wrap {
    min-width: 216px;
    .btn-search {
        height: 100%;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        color: #fff;
        border: 0;
        cursor: pointer;
        position: relative;
        z-index: 0;
        background: #00ad5f;
        transition: all .2s ease-out, color .2s ease-out;
        font-weight: 300;

        &:hover {
            background: #009451;
          }
        &:focus {
            outline: 0;
            box-shadow: none;
          }
      }

  }
  
  @media (min-width: 640px) {
  
  }
  
  @media screen and (max-width: 992px) {
    .dialog_search form .inner-form .input-field {
      height: 50px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .dialog_search form .inner-form .input-field.first-wrap .icon_search_dialog {
      min-width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
    .dialog_search form .inner-form .input-field.second-wrap {
      min-width: 100px;
      .btn-search {
        font-size: 13px;
      }
    }
  }
  
  