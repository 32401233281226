/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer p {
    text-align: center;
    padding: 30px 0px;
    color: #fff;
    font-weight: 300;
  }
  
  footer p a {
    color: #fff;
    transition: all .3s;
  }
  
  footer p a:hover {
    color: #ec6090;
  }
  
  .img-brand-ship{
    img{
      margin-right: 15px;
      cursor: pointer;
      max-height: 60px;
      width: 50px;
    }
  }