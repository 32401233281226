/* 
---------------------------------------------
Help Bar Style
--------------------------------------------- 
*/

.help-bar .heading-section {
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;
  }
  
  .help-bar .item {
    padding: 30px;
    border: 1px solid #444;
    border-radius: 23px;
  }
  
  .help-bar .item h4 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .help-bar .main-button {
    text-align: center;
    margin-top: 30px;
  }
  