.container_order_bill{
    background-color: var(--dark-content);
}
.card{border:none}
.logo{background-color: #eeeeeea8}
.totals tr td{font-size: 13px}
.footer{background-color: #eeeeeea8}
.footer span{font-size: 12px}
.product-qty span{font-size: 12px;color: #dedbdb}
.text-title-order{
    font-size: 12px;
    font-weight: bold;
}
.order-address{
    width: 200px;
}
.order-barcode svg{
    width: 396px !important;
}
.action-receipt{
    position: relative;
}
.action-receipt button{
    position: absolute;
    z-index: 999;
    top: 60px;
    right: 24%;
}