
.input-search-tb{
    border-radius: 5px;
    background-color: #a1a4ab;
    border: none;
    outline: none;
    color: #191C24;
    padding: 10px;
}

.upcase{
    text-transform: uppercase;
}

.title-table{
    background-color: var(--background);
    padding: 10px;
    border-radius: 5px 5px 0 0;
    h5{
        color: #CCC;
        font-size: 30px;
    }
}

.btn-update-table{
    padding: 5px;
    color:#fff;
    background-color: rgb(0, 210, 91);
    border-radius: 5px;
}

.btn-delete-table{
    padding: 5px;
    color:#fff;
    background-color: rgb(252, 66, 74);
    border-radius: 5px;
}