.footer-admin{
   background-color: var(--background) !important;
}

.text-sm-end{
    color: #CCC;
    a{
        color: var(--pink);
    }
}
.text-sm-start{
    color: #CCC;
    a{
        color: var(--pink);
    }
}