/* 
---------------------------------------------
Most Popular Style
--------------------------------------------- 
*/
.most-popular {
    margin: 60px 0;
    padding: 30px;
    background-color: #1f2122;
    border-radius: 23px;
  }
  
  .most-popular .item {
    background-color: #27292a;
    padding: 30px 15px;
    border-radius: 23px;
    margin-bottom: 30px;
  }
  
  // .most-popular .item .item {
  //   padding: 0px;
  //   border-radius: 0px;
  //   background-color: transparent;
  //   margin-bottom: 0px;
  // }
  
  .most-popular .item img {
    border-radius: 23px;
  }
  
  .most-popular .item h4 {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 0px;
    display: inline-block;
  }
  
  .most-popular .item span {
    color: #666;
    display: block;
    margin-top: 7px;
    font-weight: 400;
  }
  
  .most-popular .item ul {
    float: right;
    margin-top: 20px;
  }
  
  .most-popular .item ul li {
    text-align: right;
    color: #fff;
    font-size: 14px;
  }
  
  .most-popular .item ul li:first-child i {
    color: yellow;
  }
  
  .most-popular .item ul li:last-child i {
    color: #ec6090;
  }
  
  .most-popular .main-button {
    text-align: center;
  }
  