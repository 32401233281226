.card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 1rem;
}
.text-reset {
    --bs-text-opacity: 1;
    color: inherit!important;
}
a {
    color: #5465ff;
    text-decoration: none;
}


.track{
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px}
    .track .step{
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 25%;
        margin-top: -18px;
        text-align: center;
        position: relative
    }
    .track .step.active:before{
        background: #FF5722
    }
    .track .step::before{
        height: 7px;
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        top: 18px
    }
    .track .step.active .icon{
        background: #ee5435;
        color: #fff
    }
    .track .icon{
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: relative;
        border-radius: 100%;
        background: #ddd;
    }
    .track .step.active .text{
        font-weight: 400;
        color: #000
    }
    .track .text{
        display: block;
        margin-top: 7px
    }
.action-detail-order{
    button{
        margin-right: 10px;
    }
}

.h6{
    color: var(--light-text);
}