.cart{height: 100vh}
.progresses{display: flex;align-items: center}
.line{width: 76px;height: 6px;background: #63d19e}
.line-disable{width: 76px;height: 6px;background: #c4c8c6}
.steps{display: flex;background-color: #63d19e;color: #fff;font-size: 12px;width: 30px;height: 30px;align-items: center;justify-content: center;border-radius: 50%}
.check1{display: flex;background-color: #63d19e;color: #fff;font-size: 17px;width: 60px;height: 60px;align-items: center;justify-content: center;border-radius: 50%;margin-bottom: 10px}
.error1{display: flex;background-color: #dc3545;;color: #fff;font-size: 17px;width: 60px;height: 60px;align-items: center;justify-content: center;border-radius: 50%;margin-bottom: 10px}
.invoice-link{font-size: 15px}
.order-button{height: 50px}
.background-muted{background-color:#fafafc}
.bx-time-five{
    font-size: 14px;
}
.bxs-error{
    font-size: 20px;
}
.detail-product-order{
    em{
        font-size: 12px;
    }
}
.button-page-order{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.bx-alarm-exclamation{
    font-size: 18px;
}
.text-detail-app{
    margin-top: 10px;
    font-size: 12px;
    a{
        color: #dc3545;
    }
}