/* 
---------------------------------------------
app Library Style
--------------------------------------------- 
*/

.app-library {
    margin-top: 60px;
    padding: 30px;
    background-color: #1f2122;
    border-radius: 23px;
  }
  
  .app-library .item {
    border-bottom: 1px solid #27292a;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .app-library .last-item {
    margin-bottom: 30px;
  }
  
  .profile-library .last-item {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  
  .app-library .item ul li {
    margin-top: 15px;
  }
  
  .app-library .item ul li img {
    margin-top: -30px;
  }
  
  .app-library .item ul li {
    display: inline-block;
    width: 25%;
  }
  
  .app-library .item ul li:first-child {
    width: 110px;
  }
  
  .app-library .item ul li:last-child {
    width: 15%;
    text-align: right;
  }
  
  .app-library .item ul li h4 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  
  .app-library .item ul li span {
    color: #666;
    font-size: 14px;
  }
  
  .app-library .main-button {
    text-align: center;
    margin-bottom: -53px;
  }
  